import { useEffect, useState } from 'react';
import { Card, Divider, CardContent, Modal } from '@material-ui/core';
import { format } from 'date-fns';
import styles from './Exam.module.css';
import { AsyncData, get } from '../../../../shared/apiClient';
import Loading from '../../../../shared/components/Loading';
import MessageSmoothing from '../../../../shared/components/MessageSmoothing';

type ExamsOptions = {
  examcode: string;
  born: string;
  name: string;
  sexo: string;
  procedimento: string;
  dt_exame: string;
  health_insurance: string;
  doctor: string;
  ds_exame_realizado: string;
  ds_observacao: string;
  company: string;
};

type ModalState = {
  open: boolean;
  examcode: string | undefined;
};

const Exam = ({
  modal,
  setModal,
}: {
  modal: ModalState;
  setModal: React.Dispatch<React.SetStateAction<ModalState>>;
}) => {
  const [results, setResults] = useState<AsyncData<ExamsOptions>>({
    status: 'not-loaded',
  });

  useEffect(() => {
    const handleLoadData = async () => {
      if (!modal.open || !modal.examcode) {
        return;
      }
      try {
        const responseExams = await get<any>({
          path: `/exams/${modal.examcode}`,
          credentials: true,
        });
        setResults({ status: 'loaded', data: responseExams });
      } catch (err) {
        setResults({ status: 'error' });
      }
    };
    handleLoadData();
  }, [modal.examcode]);

  return (
    <Modal
      className={styles.root}
      open={modal.open}
      onClose={() => setModal({ open: !modal.open, examcode: modal.examcode })}
    >
      <Card className={styles.container}>
        {results.status === 'loaded' && (
          <CardContent>
            <h3 className={styles.title}>
              Detalhes do exame: {results.data.examcode}
            </h3>
            <Divider />
            <div className={styles.field}>
              Nome do paciente: {results.data.name}
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                Data de nascimento:{' '}
                {results.data.born &&
                  format(new Date(results.data.born), 'dd/MM/yyyy')}
              </div>
              <div className={styles.field}>Sexo: {results.data.sexo}</div>
            </div>
            <div className={styles.field}>
              Convênio: {results.data.health_insurance}
            </div>
            <Divider style={{ marginBottom: 20 }} />
            <div className={styles.field}>Clinica: {results.data.company}</div>
            <div className={styles.field}>
              Data do exame: {results.data.dt_exame}
            </div>
            <div className={styles.field}>
              Procedimento: {results.data.procedimento}
            </div>
            <div className={styles.row}>
              <div className={styles.field}>
                Radiologista: {results.data.doctor}
              </div>
              <div className={styles.field}>
                Exame realizado: {results.data.ds_exame_realizado}
              </div>
            </div>
            <div className={styles.field}>
              Observação:
              {results.data.ds_observacao}
            </div>
          </CardContent>
        )}

        {results.status === 'loading' && <Loading />}
        {results.status === 'error' && (
          <MessageSmoothing
            title="Falha no carregamento"
            message="Tente novamente mais tarde"
          />
        )}
      </Card>
    </Modal>
  );
};

export default Exam;
