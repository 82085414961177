import { useCallback, useMemo, useState } from 'react';
import styles from './ExamsTable.module.css';
import Pagination from '../../../shared/components/Pagination';
import { AsyncData } from '../../../shared/apiClient';
import ModalExam from './ModalExam';

type ExamsOptions = {
  cd_exame: string;
  ds_paciente: string;
  dt_nascimento: string;
  ds_procedimento: string;
  ds_medico: string;
  ds_razao: string;
  nr_urgente: number;
  dt_data: string;
  dt_primeira_assinatura: string;
};

type ExamsResultOptions = {
  results: AsyncData<{
    exams: ExamsOptions[];
    itemsLength: number;
  }>;
  handlePage: (pg: number) => void;
  selectedPage: number;
};

type ModalState = {
  open: boolean;
  examcode: string | undefined;
};

const NUMBER_OF_ITEMS_PER_PAGE = 100;

const Exams = ({ selectedPage, handlePage, results }: ExamsResultOptions) => {
  const [modal, setModal] = useState<ModalState>({
    open: false,
    examcode: undefined,
  });
  const pages = useMemo(() => {
    if (results.status !== 'loaded') {
      return [0];
    }
    if (results.data.itemsLength === 0) {
      return [0];
    }
    const pageAmount = Math.ceil(
      results.data.itemsLength / NUMBER_OF_ITEMS_PER_PAGE
    );
    return Array(pageAmount)
      .fill(0)
      .map((_vl, index) => index);
  }, [results]);

  const handleExamPage = useCallback((examcode: string) => {
    setModal({ open: true, examcode });
  }, []);

  return (
    <div className={styles.root}>
      <ModalExam modal={modal} setModal={setModal} />
      {results.status === 'loaded' && (
        <table className={styles.table}>
          <thead>
            <tr className={styles.tableHeader}>
              <th>Examcode</th>
              <th>Paciente</th>
              <th>Nasci.</th>
              <th>Procedimento</th>
              <th>Clínica</th>
              <th>Radiologista</th>
              <th>Prioridade</th>
              <th>Exame</th>
              <th>Laudo</th>
            </tr>
          </thead>
          {results.data.exams.length !== 0 ? (
            <tbody>
              {results.data.exams.map((exam) => (
                <tr
                  key={exam.cd_exame}
                  className={styles.row}
                  onClick={() => handleExamPage(exam.cd_exame)}
                >
                  <td>{exam.cd_exame}</td>
                  <td>{exam.ds_paciente}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {exam.dt_nascimento && exam.dt_nascimento.substring(0, 10)}
                  </td>
                  <td>{exam.ds_procedimento}</td>
                  <td>{exam.ds_razao}</td>
                  <td>{exam.ds_medico}</td>
                  <td>{exam.nr_urgente === 1 ? 'URGENTE' : 'ROTINA'}</td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {exam.dt_data.substring(0, 10)}
                  </td>
                  <td style={{ whiteSpace: 'nowrap' }}>
                    {exam.dt_primeira_assinatura &&
                      exam.dt_primeira_assinatura.substring(0, 10)}
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className={styles.empty} colSpan={9}>
                  Não existem eventos para esta estação no período informado
                </td>
              </tr>
            </tbody>
          )}
        </table>
      )}
      {results.status === 'loaded' && (
        <div className={styles.wrapperPagination}>
          <Pagination
            className={styles.pagination}
            handlePage={handlePage}
            selectedPage={selectedPage}
            pages={pages}
          />
        </div>
      )}
    </div>
  );
};

export default Exams;
