/**
 * example=?property=value&other-property=value
 */
type searchParams = string;

const normalizeValue = (key: string, value: any) =>
  key === '' ? value : decodeURIComponent(value);

const formatSearchParamsToObject = <T>(search: searchParams): T => {
  const replaceSearch = search
    .replace(/&/g, '","')
    .replaceAll('+', ' ')
    .replace(/=/g, '":"')
    .replace('?', '');

  const JSONsearch = '{"' + replaceSearch + '"}';
  const object = JSON.parse(JSONsearch, normalizeValue);
  const data: any = {};
  for (const property in object) {
    if (object[property] !== 'undefined' && object[property] !== 'null') {
      data[property] = object[property];
    } else {
      data[property] = undefined;
    }
  }
  return data as T;
};

export default formatSearchParamsToObject;
