import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AsyncData, get } from '../../shared/apiClient';

import MedcloudReportTable from './MedcloudReportTable';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/Loading';
import MessageSmoothing from '../../shared/components/MessageSmoothing';
import formatSearchParamsToObject from '../../shared/helpers/formatSearchParamsToObject';
import styles from './MedcloudReport.module.css';
import Input from '../../shared/components/Input';
import { useForm } from '../../shared/useForm';
import Button from '../../shared/components/Button';

type MedcloudReportsOptions = {
  id: number;
  pat_name: string;
  exam_code: number;
  study_desc: string;
  identifier_type: string;
  identifier_value: string;
  report_datetime: string;
  last_sign: string;
  is_republished: boolean;
  share_datetime: string;
  http_status_code: number;
  http_response: string;
  retries: number;
  facility: string;
  send: boolean;
};

type ExamsResultOptions = {
  rows: MedcloudReportsOptions[];
  sizeRows: number;
};

interface FormValues {
  patient: string;
  exam_code: string;
}

type Fields = {
  id: number;
  name: string;
};

type SearchsFields = {
  companies: Fields[] | [];
  procedures: Fields[] | [];
  radiologists: Fields[] | [];
};

const Exams = () => {
  const [results, setResults] = useState<AsyncData<ExamsResultOptions>>({
    status: 'not-loaded',
  });
  const [searchs, setSearchs] = useState<AsyncData<SearchsFields>>({
    status: 'not-loaded',
  });

  const { search } = useLocation();

  useEffect(() => {
    const handleLoadData = async () => {
      setResults({ status: 'loading' });
      setSearchs({ status: 'loading' });
      try {
        const query = search
          ? formatSearchParamsToObject<any>(search)
          : undefined;

        const requestReports = get<ExamsResultOptions>({
          path: `/medcloud-reports`,
          query,
          credentials: true,
        });
        const requestSearchs = get<SearchsFields>({
          path: `/searchs`,
          credentials: true,
          query: {
            companies: true,
            procedures: false,
            radiologists: false,
          },
        });

        const [responseReports, responseSearchs] = await Promise.all([
          requestReports,
          requestSearchs,
        ]);
        setResults({
          status: 'loaded',
          data: {
            rows: responseReports.rows,
            sizeRows: Number(responseReports.sizeRows),
          },
        });

        setSearchs({
          status: 'loaded',
          data: responseSearchs,
        });
      } catch (err) {
        setSearchs({ status: 'error' });
        setResults({ status: 'error' });
        toast.error('Falha ao carregar dados');
      }
    };
    handleLoadData();
  }, []);

  const { handleChange, handleSubmit, errorIfDirty, values } =
    useForm<FormValues>({
      initialValues: {
        patient: '',
        exam_code: '',
      },
      onSubmit: async (validValues) => {
        try {
          setResults({ status: 'loading' });

          const response = await get<any>({
            path: `/medcloud-reports`,
            query: {
              exam_code: validValues.exam_code,
              patient: validValues.patient,
            },
            credentials: true,
          });

          setResults({ status: 'loaded', data: response });

          return 'screen-still-alive';
        } catch (err: any) {
          setResults({ status: 'error' });
          toast.error('Falha ao carregar dados');
          return 'screen-still-alive';
        }
      },
    });

  return (
    <div className={styles.root}>
      {searchs.status === 'loaded' && (
        <div className={styles.container}>
          {results.status === 'loading' && <span>...</span>}
          {results.status === 'error' && (
            <span className={styles.error}>
              Não foi possível carregar as opções de filtro!
            </span>
          )}
          {searchs.status === 'loaded' && (
            <form
              className={styles.toolbar}
              onSubmit={handleSubmit}
              data-cy="form"
            >
              <Input
                id="patient"
                placeholder="nome da empresa"
                onChange={(e) => {
                  handleChange({ patient: e.target.value }, 'store-only');
                }}
                value={values.patient}
                errorMessage={errorIfDirty('patient')}
              />
              <Input
                id="exam_code"
                placeholder="Código do exame"
                onChange={(e) => {
                  handleChange({ exam_code: e.target.value }, 'store-only');
                }}
                value={values.exam_code}
                errorMessage={errorIfDirty('exam_code')}
              />
              <Button
                className={styles.submit}
                disabled={results.status === 'loading'}
                type="submit"
                style={{ height: 43 }}
              >
                {results.status === 'loading' ? 'Carregando' : 'Buscar'}
              </Button>
            </form>
          )}
        </div>
      )}
      <MedcloudReportTable results={results} />

      {results.status === 'loading' && (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
          <Loading />
        </div>
      )}
      {results.status === 'error' && (
        <MessageSmoothing
          title="Problemas ao carregar dados"
          message="Falha no carregamento, tente novamente mais tarde"
        />
      )}
    </div>
  );
};

export default Exams;
