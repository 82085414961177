import 'react-datepicker/dist/react-datepicker.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import CustomToastContainer from './core/CustomToastContainer';
import SessionProvider from './core/Session/SessionProvider';
import GlobalStyles from './core/GlobalStyle';
// import ThemeProviderCustom from './theme';

const App = (): JSX.Element => (
  <BrowserRouter>
    <SessionProvider>
      <GlobalStyles />
      <CssBaseline />
      <CustomToastContainer />
      <Routes />
    </SessionProvider>
  </BrowserRouter>
);

export default App;
