import { useState } from 'react';

export type SubmissionNavigationResult =
  | 'screen-still-alive'
  | 'screen-destroyed';

type FormError = string;
type FormErrors = { any: boolean; messages: { [key: string]: FormError } };

type FormValues = { [key: string]: any };

interface Config<V extends FormValues> {
  initialValues: V;
  validate?: (
    valuesToValidate: V,
    registerError: (key: string, message: string) => void
  ) => void;
  onSubmit: (validValues: V) => Promise<SubmissionNavigationResult>;
}

export function useForm<V extends FormValues>({
  initialValues,
  validate,
  onSubmit,
}: Config<V>) {
  const [values, setValues] = useState(() => initialValues);
  const [errors, setErrors] = useState<FormErrors>(() =>
    runValidation(initialValues)
  );
  const [submitting, setSubmitting] = useState(false);
  const [dirtyKeys, setDirtyKeys] = useState<{ [key: string]: 1 }>({});

  const dirty = Object.keys(dirtyKeys).length > 0;

  function runValidation(valuesToValidate: V): FormErrors {
    const validationErrors: FormErrors = { any: false, messages: {} };
    const registerError = (key: string, message: string) => {
      if (!validationErrors.any) validationErrors.any = true;
      validationErrors.messages[key] = validationErrors.messages[key]
        ? `${validationErrors.messages[key]}, ${message}`
        : message;
    };
    validate && validate(valuesToValidate, registerError);
    return validationErrors;
  }

  const clearDirty = (keyOrKeys: string[] | string) => {
    const keys = typeof keyOrKeys === 'string' ? [keyOrKeys] : keyOrKeys;
    const newDirtyKeys = { ...dirtyKeys };
    keys.forEach((key) => delete newDirtyKeys[key]);
    setDirtyKeys(newDirtyKeys);
  };

  const markAsDirty = (keyOrKeys: string[] | string) => {
    const keys = typeof keyOrKeys === 'string' ? [keyOrKeys] : keyOrKeys;
    const newDirtyKeys = { ...dirtyKeys };
    keys.forEach((key) => {
      newDirtyKeys[key] = 1;
    });
    setDirtyKeys(newDirtyKeys);
  };

  const handleChange = (
    patch: FormValues,
    mode: 'mark-as-dirty' | 'clear-dirty' | 'store-only'
  ) => {
    const newValues = { ...values, ...patch };
    setValues(newValues);
    setErrors(runValidation(newValues));
    if (mode === 'mark-as-dirty') {
      markAsDirty(Object.keys(patch));
    } else if (mode === 'clear-dirty') {
      clearDirty(Object.keys(patch));
    }
  };

  const allKeysDirty = (x: FormValues): { [key: string]: 1 } => {
    const result: { [key: string]: 1 } = {};
    Object.keys(x).forEach((key) => {
      result[key] = 1;
    });
    return result;
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (errors.any) {
      setDirtyKeys(allKeysDirty(values));
      return;
    }
    setSubmitting(true);
    const submissionNavigationResult = await onSubmit(values);
    if (submissionNavigationResult === 'screen-still-alive') {
      setDirtyKeys({});
      setSubmitting(false);
    }
  };

  const errorIfDirty = (key: string): string | undefined => {
    if (dirtyKeys[key] === 1) {
      return errors.messages[key];
    } else {
      return undefined;
    }
  };

  return {
    handleChange,
    handleSubmit,
    markAsDirty,
    clearDirty,
    errorIfDirty,
    values,
    dirty,
    submitting,
  };
}
