import styles from './DashScreen.module.css';
import Navbar from './Navbar';
// import DashboardNavbar from './DashboardNavbar';
// import DashboardSidebar from './DashboardSidebar';

const DashboardLayout = ({ children }: any) => {
  return (
    <div className={styles.container}>
      <Navbar />
      {children}
    </div>
  );
};

export default DashboardLayout;
