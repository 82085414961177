/* eslint-disable prefer-const */
import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AsyncData, get } from '../../shared/apiClient';

import ExamsToolbar from './ExamsToolbar';
import ExamsTable from './ExamsTable';
import { toast } from 'react-toastify';
import Loading from '../../shared/components/Loading';
import MessageSmoothing from '../../shared/components/MessageSmoothing';
import formatSearchParamsToObject from '../../shared/helpers/formatSearchParamsToObject';
import { format } from 'date-fns';
import styles from './Exams.module.css';

type ExamsOptions = {
  cd_exame: string;
  ds_paciente: string;
  dt_nascimento: string;
  ds_procedimento: string;
  ds_medico: string;
  ds_razao: string;
  nr_urgente: number;
  dt_data: string;
  dt_primeira_assinatura: string;
};

type ExamsResultOptions = {
  exams: ExamsOptions[];
  itemsLength: number;
};

interface FormValues {
  examcode: string;
  patient: string;
  procediment: string;
  doctor: string;
  company: string;
  level: string;
  limit: '50' | '100';
  status: 'registrado' | 'laudado' | 'revision' | 'espera' | '';
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

const Exams = () => {
  const [results, setResults] = useState<AsyncData<ExamsResultOptions>>({
    status: 'not-loaded',
  });

  const [selectedPage, setSelectedPage] = useState(0);

  const history = useHistory();
  const { search } = useLocation();

  const handleSearch = useCallback(
    async ({
      patient,
      company,
      doctor,
      procediment,
      level,
      status,
      startDate,
      endDate,
      limit,
      examcode,
    }: FormValues) => {
      try {
        setResults({ status: 'loading' });
        examcode = examcode || '';
        company = company || '';
        patient = patient || '';
        doctor = doctor || '';
        procediment = procediment || '';
        level = level || '';
        status = status || '';

        let initialDate = undefined;
        let finalDate = undefined;
        if (startDate) {
          initialDate = format(new Date(startDate), 'yyyy-MM-dd');
        }
        if (endDate) {
          finalDate = format(new Date(endDate), 'yyyy-MM-dd');
        }

        const paramsObject = new URLSearchParams({
          examcode,
          company: company,
          patient,
          doctor,
          procediment,
          level,
          status,
          startDate: initialDate || '',
          endDate: finalDate || '',
        }).toString();

        history.push({ search: paramsObject });
        setSelectedPage(0);
        const response = await get<any>({
          path: `/exams`,
          query: {
            examcode,
            company,
            patient,
            doctor,
            procediment,
            level,
            status,
            startDate: initialDate,
            endDate: finalDate,
            limit,
          },
          credentials: true,
        });

        setResults({ status: 'loaded', data: response });

        toast.error({ severity: 'success', message: 'Sucesso' });
      } catch (err) {
        setResults({ status: 'error' });
        toast.error({ severity: 'error', message: 'Falha ao carregar dados' });
      }
    },
    []
  );

  const handlePage = useCallback(
    async (pg: number) => {
      try {
        setResults({ status: 'loading' });
        let {
          examcode = '',
          company = '',
          patient = '',
          doctor = '',
          procediment = '',
          level = '',
          status = '',
          startDate = undefined,
          endDate = undefined,
        } = search
          ? formatSearchParamsToObject<any>(search)
          : {
              company: '',
              patient: '',
              doctor: '',
              procediment: '',
              level: '',
              status: '',
              startDate: undefined,
              endDate: undefined,
            };
        setSelectedPage(pg);
        if (!examcode) {
          examcode = '';
        }
        if (!company) {
          company = '';
        }
        if (!patient) {
          patient = '';
        }
        if (!doctor) {
          doctor = '';
        }
        if (!procediment) {
          procediment = '';
        }
        if (!level) {
          level = '';
        }
        if (!status) {
          status = '';
        }
        let initialDate = undefined;
        let finalDate = undefined;
        if (startDate) {
          initialDate = format(new Date(startDate), 'yyyy-MM-dd');
        }
        if (endDate) {
          finalDate = format(new Date(endDate), 'yyyy-MM-dd');
        }

        const paramsObject = new URLSearchParams({
          examcode,
          company,
          patient,
          doctor,
          procediment,
          level,
          status,
          startDate: initialDate || '',
          endDate: finalDate || '',
        }).toString();

        history.push({ search: paramsObject });

        const response = await get<any>({
          path: '/exams',
          query: {
            examcode,
            offset: pg,
            company,
            patient,
            doctor,
            procediment,
            level,
            status,
          },
          credentials: true,
        });
        setResults({ status: 'loaded', data: response });
      } catch (error) {
        setResults({ status: 'error' });
      }
    },
    [search, history]
  );

  return (
    <div className={styles.root}>
      <ExamsToolbar handleSearch={handleSearch} results={results} />
      <ExamsTable
        results={results}
        selectedPage={selectedPage}
        handlePage={handlePage}
      />

      {results.status === 'loading' && (
        <div style={{ width: '100%', backgroundColor: '#fff' }}>
          <Loading />
        </div>
      )}
      {results.status === 'error' && (
        <MessageSmoothing
          title="Problemas ao carregar dados"
          message="Falha no carregamento, tente novamente mais tarde"
        />
      )}

      {results.status === 'not-loaded' && (
        <MessageSmoothing
          title="Faça sua consulta"
          message="Selecione os filtros e faça sua consulta"
        />
      )}
    </div>
  );
};

export default Exams;
