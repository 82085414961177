import styles from './MessageSmoothing.module.css';

interface MessageSmoothingParams {
  title: string;
  message: string;
  insideBox?: boolean | undefined;
}

const MessageSmoothing = ({
  title,
  message,
  insideBox,
}: MessageSmoothingParams) => {
  return (
    <div className={insideBox ? styles.root : styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>{message}</div>
    </div>
  );
};

export default MessageSmoothing;
