import ReportsWeeksPriority from './ReportsWeeksPriority';
import ReportsWeeksOrigin from './ReportsWeeksOrigin';
import ReportsDays from './ReportsDays';
import styles from './OverviewGraphs.module.css';
import TotalReports from './TotalReports';
import Datepicker from '../../shared/components/Datepicker';
import { useForm } from '../../shared/useForm';
import Button from '../../shared/components/Button';
import { AsyncData, get } from '../../shared/apiClient';
import { useEffect, useState } from 'react';
import MessageSmoothing from '../../shared/components/MessageSmoothing';
import Loading from '../../shared/components/Loading';
import { format } from 'date-fns';

type FormValues = {
  startDate: Date | undefined;
  endDate: Date | undefined;
};

type urForDaysOptions = {
  date: string;
  ur: number;
  urgentes: number;
  rotinas: number;
};

type urForWeekByOriginsptions = {
  date: string;
  ur: string;
  telelaudo: string;
  diagrad: string;
  publico: string;
};

type urForWeekByPriorityptions = {
  date: string;
  ur: string;
  telelaudo: string;
  diagrad: string;
  publico: string;
};

type countPriority = {
  rotinas: number;
  prioridades: number;
  urgentes: number;
  total: number;
};

type overviewData = {
  urForDays: urForDaysOptions[];
  urForWeekByOrigins: urForWeekByOriginsptions[];
  urWeekByPriority: urForWeekByPriorityptions[];
  countPriority: countPriority;
};

const OverviewGraphs = () => {
  const [results, setResults] = useState<AsyncData<overviewData>>({
    status: 'not-loaded',
  });
  useEffect(() => {
    (async () => {
      setResults({ status: 'loading' });
      try {
        const resp = await get<overviewData>({
          path: '/overview/ur',
          credentials: true,
        });

        setResults({ status: 'loaded', data: resp });
      } catch (err) {
        setResults({ status: 'error' });
      }
    })();
  }, []);

  const { handleChange, handleSubmit, errorIfDirty, values } =
    useForm<FormValues>({
      initialValues: {
        startDate: undefined,
        endDate: undefined,
      },
      validate: (valuesToValidate, registerError) => {
        if (!valuesToValidate.startDate) {
          return registerError('startDate', 'Campo obrigatório.');
        }
        if (!valuesToValidate.endDate) {
          return registerError('endDate', 'Campo obrigatório.');
        }
        if (
          valuesToValidate.startDate.getTime() >
          valuesToValidate.endDate.getTime()
        ) {
          registerError('startDate', 'Intervalo inválido');
          registerError('endDate', 'Intervalo inválido');
          return;
        }
        if (
          valuesToValidate.startDate.getDate() ===
          valuesToValidate.endDate.getDate()
        ) {
          registerError('startDate', 'Use um intervalo maior');
          return;
        }
      },
      onSubmit: async (validValues) => {
        if (!validValues.startDate || !validValues.endDate) {
          return 'screen-still-alive';
        }
        setResults({ status: 'loading' });
        try {
          const resp = await get<overviewData>({
            path: '/overview/ur',
            credentials: true,
            query: {
              startDate: format(validValues.startDate, 'yyyy-MM-dd'),
              endDate: format(validValues.endDate, 'yyyy-MM-dd'),
            },
          });

          setResults({ status: 'loaded', data: resp });

          return 'screen-still-alive';
        } catch (err: any) {
          setResults({ status: 'error' });
          return 'screen-still-alive';
        }
      },
    });

  return (
    <div className={styles.container}>
      <form onSubmit={handleSubmit} className={styles.toolbar}>
        <Datepicker
          placeholderText="DD/MM/AAAA"
          id="startDate"
          label="Data inicial"
          value={values.startDate}
          className={styles.datepicker}
          onChange={(date) => handleChange({ startDate: date }, 'store-only')}
          error={errorIfDirty('startDate')}
        />
        <Datepicker
          placeholderText="DD/MM/AAAA"
          id="endDate"
          label="Data final"
          value={values.endDate}
          className={styles.datepicker}
          onChange={(date) =>
            handleChange({ endDate: date || undefined }, 'store-only')
          }
          error={errorIfDirty('endDate')}
        />
        <Button
          type="submit"
          disabled={results.status === 'loading'}
          style={{ height: 50 }}
        >
          {results.status === 'loading' ? 'Carregando' : 'Buscar'}
        </Button>
      </form>

      {results.status === 'loaded' && (
        <>
          <ReportsDays data={results.data.urForDays} />
          <div className={styles.row}>
            <ReportsWeeksPriority data={results.data.urWeekByPriority} />
            <TotalReports data={results.data.countPriority} />
          </div>
          <ReportsWeeksOrigin data={results.data.urForWeekByOrigins} />
        </>
      )}

      {results.status === 'loading' && (
        <div style={{ background: '#fff', padding: 5 }}>
          <Loading />
        </div>
      )}
      {results.status === 'error' && (
        <MessageSmoothing
          title="Falha no carregamento"
          message="Tente novamente carregar a página ou alterar os filtros"
        />
      )}
    </div>
  );
};

export default OverviewGraphs;
