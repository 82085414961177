import { format } from 'date-fns';
import styles from './MedcloudReportTable.module.css';
import { AsyncData } from '../../../shared/apiClient';

type MedcloudReportsOptions = {
  id: number;
  pat_name: string;
  exam_code: number;
  study_desc: string;
  identifier_type: string;
  identifier_value: string;
  report_datetime: string;
  last_sign: string;
  is_republished: boolean;
  share_datetime: string;
  http_status_code: number;
  http_response: string;
  retries: number;
  facility: string;
  send: boolean;
};

type MedcloudReportsResultOptions = {
  results: AsyncData<{
    rows: MedcloudReportsOptions[];
    sizeRows: number;
  }>;
};

const MedcloudReports = ({ results }: MedcloudReportsResultOptions) => {
  return (
    <div className={styles.root}>
      {results.status === 'loaded' && (
        <table className={styles.table}>
          <thead>
            <tr className={styles.header}>
              <th>exam_code</th>
              <th>Paciente</th>
              <th>Procedimento</th>
              <th>Tipo</th>
              <th>Data do laudo</th>
              <th>last_sign</th>
              <th>republicada</th>
              <th>http</th>
              <th>retries</th>
              <th>facility</th>
            </tr>
          </thead>
          <tbody>
            {results.data.rows.map((exam: any) => (
              <tr key={exam.id} className={styles.row}>
                <td>{exam.exam_code}</td>
                <td>{exam.pat_name}</td>
                <td>{exam.study_desc}</td>
                <td>{exam.identifier_type}</td>
                <td>{exam.identifier_value}</td>
                <td>
                  {exam.report_datetime &&
                    format(new Date(exam.report_datetime), 'yyyy-MM-dd')}
                </td>
                <td>
                  {exam.last_sign &&
                    format(new Date(exam.last_sign), 'yyyy-MM-dd')}
                </td>
                <td title={exam.http_response}>{exam.http_response}</td>
                <td>{exam.retries}</td>
                <td>{exam.facility}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MedcloudReports;
