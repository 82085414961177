import { BrowserRouter as Routes, Route, Redirect } from 'react-router-dom';
import { resolveAuthToken } from '../shared/authToken';
import DashScreen from '../core/DashScreen';
import AuthScreen from '../core/AuthScreen';
import Login from '../features/Login';
import OverviewGraphs from '../features/OverviewGraphs';
import Exams from '../features/Exams';
import LatestWeekTable from '../features/LatestWeekTable';
import OverviewCompanies from '../features/OverviewCompanies';
import MedcloudReports from '../features/MedCloudReports';

const RootRoutes = () => (
  <Routes>
    <Route exact path="/">
      <AuthScreenWrapper>
        <Login />
      </AuthScreenWrapper>
    </Route>

    <Route exact path="/app/dashboard/overview">
      <RequireAuthWrapper>
        <OverviewGraphs />
      </RequireAuthWrapper>
    </Route>

    <Route exact path="/app/dashboard/exames">
      <RequireAuthWrapper>
        <Exams />
      </RequireAuthWrapper>
    </Route>

    <Route exact path="/app/dashboard/overview/latest-week">
      <RequireAuthWrapper>
        <LatestWeekTable />
      </RequireAuthWrapper>
    </Route>

    <Route exact path="/app/dashboard/overview/companies">
      <RequireAuthWrapper>
        <OverviewCompanies />
      </RequireAuthWrapper>
    </Route>

    <Route exact path="/app/dashboard/medcloud">
      <RequireAuthWrapper>
        <MedcloudReports />
      </RequireAuthWrapper>
    </Route>
    <Route path="*">
      <div>not found</div>
    </Route>
  </Routes>
);

function AuthScreenWrapper({ children }: { children: any }): JSX.Element {
  const authToken = resolveAuthToken();
  return !authToken ? (
    <AuthScreen>{children}</AuthScreen>
  ) : (
    <Redirect to="/app/dashboard/overview" />
  );
}

function RequireAuthWrapper({ children }: { children: any }): JSX.Element {
  const authToken = resolveAuthToken();
  return authToken ? <DashScreen>{children}</DashScreen> : <Redirect to="/" />;
}

export default RootRoutes;
