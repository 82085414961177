import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styles from './AutoComplete.module.css';
import formatSearchParamsToObject from '../../helpers/formatSearchParamsToObject';

interface AutoCompleteParams {
  options: Options[] | [];
  handleChange: any;
  errorMessage?: string | undefined;
  onBlur?: any;
  initialValue?: string | undefined;
  id: string;
  placeholder: string;
  [key: string]: any;
}

interface Options {
  name: string;
  id: number;
}

interface OptionsState {
  name: string | undefined;
  id: number | undefined;
  text: string;
}

const AutoComplete = ({
  options,
  handleChange,
  dataCy,
  errorMessage,
  id,
  placeholder,
  onBlur,
  ...other
}: AutoCompleteParams) => {
  const { search } = useLocation();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<OptionsState>({
    name: undefined,
    id: undefined,
    text: '',
  });

  useEffect(() => {
    if (!search) {
      return;
    }
    const data = formatSearchParamsToObject<any>(search);
    if (data[id]) {
      const result = options.find((option) => option.id === Number(data[id]));
      if (result) {
        return handleSelect(result);
      }
      handleChangeText(undefined);
    }
  }, [search]);

  useEffect(() => {
    if (open === true)
      window.addEventListener('click', (event: any) => {
        const div = event.path
          .map((path: any) => path.id)
          .find((id: string) => id === 'custom-input');

        if (!div && open) {
          setOpen(false);
        }
      });
  }, [open]);

  const handleChangeText = (value: any) => {
    if (!value) {
      handleChange(undefined);
    }
    setSelected({
      name: undefined,
      id: undefined,
      text: value.toUpperCase(),
    });
  };

  const handleSelect = (data: any) => {
    setSelected({
      id: data.id,
      name: data.name,
      text: `${data.id} ${data.name.toUpperCase()}`,
    });
    handleChange(data.id);
    handleOpen(false);
  };

  const arrayOptions = useMemo(
    () =>
      selected
        ? options.map((option) => ({
            ...option,
            text: `${option.id} ${option.name.toUpperCase()}`,
          }))
        : [],
    [options, selected]
  );

  const listOptions = useMemo(
    () => arrayOptions.filter((option) => option.text.includes(selected.text)),
    [selected]
  );

  const handleOpen = (data: boolean) => setOpen(data);

  return (
    <div
      className={styles.container}
      {...other}
      data-cy={dataCy}
      id="custom-input"
    >
      <div className={styles.select}>
        <input
          onBlur={onBlur}
          id={id}
          className={`${styles.input} ${errorMessage ? styles.error : ''}`}
          onChange={(e) => handleChangeText(e.target.value)}
          value={selected.text}
          onClick={() => handleOpen(true)}
          placeholder={placeholder}
        />
        <div className={`${styles.selectContent} ${open && styles.selectShow}`}>
          {listOptions.map((option) => (
            <div
              data-cy={`option-${option.id}`}
              className={styles.option}
              key={option.id}
              onClick={() => handleSelect(option)}
            >
              <span>{option.text}</span>
            </div>
          ))}
        </div>
      </div>
      <span data-cy={`error-message-${dataCy}`} className={styles.errorMessage}>
        {errorMessage}
      </span>
    </div>
  );
};

export default AutoComplete;
