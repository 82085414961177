import {
  Drawer,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from '@material-ui/core';
import { BarChart as BarChartIcon } from 'react-feather';
import {
  Dashboard as DashboardIcon,
  DescriptionRounded,
  FilterFramesOutlined,
  EqualizerRounded,
  DataUsageRounded,
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';

export default function PermanentDrawerLeft() {
  const history = useHistory();
  const items = [
    {
      href: '/app/dashboard/overview',
      icon: <BarChartIcon />,
      title: 'Visão Geral',
    },
    {
      href: '/app/dashboard/overview/companies',
      icon: <DashboardIcon />,
      title: 'Visão por Empresa',
    },
    // {
    //   href: '/app/dashboard/overview/night',
    //   icon: <EqualizerRounded />,
    //   title: 'Controle de qualidade',
    // },
    {
      href: '/app/dashboard/overview/latest-week',
      icon: <DataUsageRounded />,
      title: 'UR - ultimas semanas',
    },
    {
      href: '/app/dashboard/exames',
      icon: <FilterFramesOutlined />,
      title: 'Exames',
    },
    {
      href: '/app/dashboard/medcloud',
      icon: <DescriptionRounded />,
      title: 'Medcloud - Laudos',
    },
  ];

  return (
    <Drawer variant="permanent" anchor="left">
      <Toolbar />
      <Divider />
      <List>
        {items.map((item) => (
          <ListItem
            button
            onClick={() => history.push(item.href)}
            key={item.href}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.title} />
          </ListItem>
        ))}
      </List>
      <Divider />
    </Drawer>
  );
}
