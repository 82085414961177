import { createContext } from 'react';

import { SessionContextType } from './model';

export default createContext<SessionContextType>({
  user: null,
  async onLogin(): Promise<void> {
    throw new Error(
      'SessionContext: calling onLogin without a SessionContext provider!'
    );
  },
});
