import { useMemo } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import MessageSmoothing from '../../../shared/components/MessageSmoothing';

const CustomizedAxisTick = ({ x, y, payload }: any) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-45)"
    >
      {payload.value}
    </text>
  </g>
);

const AllReportsWeeksOrigin = ({ data }: any) => {
  const ticks = useMemo(() => {
    const ur = data.map((dt: any) => dt.ur);
    const maxTicks = Math.max(...ur);
    return [
      maxTicks,
      (maxTicks * 75) / 100,
      (maxTicks * 50) / 100,
      (maxTicks * 25) / 100,
      (maxTicks * 1) / 100,
    ].map((vl: number) => parseInt(`${vl}`));
  }, [data]);

  return (
    <Card style={{ height: 600, marginTop: 20 }}>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <CardHeader title="Evolução semanal por empresa (UR)" />
      </header>
      {data.length === 0 && (
        <MessageSmoothing
          title="Sem dados no intervalo definido"
          message="Aumente o intervalo no filtro para que tenha uma visualização"
          insideBox
        />
      )}
      {data.length !== 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              right: 50,
              left: 20,
              bottom: 150,
              top: 20,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
            <YAxis ticks={ticks} />
            <Tooltip labelStyle={{ color: '#000' }} />
            <Legend
              align="center"
              alignmentBaseline="central"
              verticalAlign="top"
              height={30}
              payload={[
                { value: 'ur', type: 'star', color: '#5664d2' },
                { value: 'telelaudo', type: 'star', color: '#E53935' },
                { value: 'diagrad', type: 'star', color: '#82ca9d' },
                { value: 'publicos', type: 'star', color: '#4B0082' },
              ]}
            />
            <Line type="linear" dataKey="ur" stroke="#5664d2" fill="#5664d2" />
            <Line
              type="linear"
              dataKey="telelaudo"
              stroke="#E53935"
              fill="#E53935"
            />
            <Line
              type="linear"
              dataKey="diagrad"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
            <Line
              type="linear"
              dataKey="publicos"
              stroke="#4B0082"
              fill="#4B0082"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default AllReportsWeeksOrigin;
