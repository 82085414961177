/* eslint-disable no-constant-condition */
import { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';
import Loader from './Loader';

interface ButtonParams extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string;
  className?: string | undefined;
  loading?: boolean | undefined;
  disabled?: boolean | undefined;
}

const Button = ({
  children,
  className,
  loading,
  disabled,
  ...rest
}: ButtonParams): JSX.Element => {
  return (
    <button
      disabled={disabled || loading}
      className={`${styles.button} ${loading && styles.loading} ${className}`}
      {...rest}
    >
      {loading ? <Loader /> : children}
    </button>
  );
};

export default Button;
