import { InputHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import styles from './Input.module.css';

interface InputParams extends InputHTMLAttributes<HTMLInputElement> {
  className?: string | undefined;
  errorMessage?: string;
  error?: boolean | undefined;
  link?: {
    to: string;
    text: string;
  };
}

const Input = ({
  className,
  errorMessage,
  link,
  ...rest
}: InputParams): JSX.Element => {
  return (
    <>
      <div className={styles.wrapperLink}>
        <input
          className={`${styles.input} ${
            errorMessage && styles.error
          } ${className}}`}
          {...rest}
        />
        {link && (
          <Link className={styles.link} to={link.to}>
            {link?.text}
          </Link>
        )}
      </div>
      <span className={styles.errorMessage} data-cy="error-message">
        {errorMessage}
      </span>
    </>
  );
};

export default Input;
