/* eslint-disable react/no-children-prop */
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CustomToastContainer = (): JSX.Element => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    theme="colored"
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
    pauseOnHover
    children={TextToastify}
    bodyClassName="data-cy-toast"
  />
);

const TextToastify = (data: any) => <div data-cy="toast">{data}</div>;

export default CustomToastContainer;
