import Datepicker from 'react-datepicker';
import styles from './Datepicker.module.css';
import { eachYearOfInterval } from 'date-fns';

interface DatepickerComponentParams {
  className?: string;
  label?: string | undefined;
  value?: Date | null | undefined;
  name?: string;
  placeholderText: string;
  id?: string;
  onChange: (date: Date) => void;
  error: string | undefined;
}

const DatepickerComponent = ({
  className,
  label,
  name,
  onChange,
  value,
  placeholderText,
  id,
  error,
}: DatepickerComponentParams): JSX.Element => {
  const months = [
    'Jan',
    'Fev',
    'Mar',
    'Abril',
    'Maio',
    'Jun',
    'Jul',
    'Ago',
    'Set',
    'Out',
    'Nov',
    'Dez',
  ];

  const years = eachYearOfInterval({
    start: new Date('2020-01-02'),
    end: new Date(),
  }).map((date) => date.getFullYear());

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <label
          htmlFor={id}
          className={`${styles.label} ${error && styles.error}`}
        >
          {label}
        </label>
        <Datepicker
          id={id}
          name={name}
          onChange={onChange}
          placeholderText={placeholderText}
          className={`${styles.datepicker} ${
            error && styles.error
          } ${className}`}
          selected={value}
          dateFormat="dd/MM/yyyy"
          maxDate={new Date()}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div className={styles.header}>
              <select
                value={date.getFullYear()}
                onChange={({ target: { value } }) => changeYear(Number(value))}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[date.getMonth()]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
        />
        <label htmlFor={id} className={styles.icon}>
          <img
            src={`${process.env.PUBLIC_URL}/assets/calendar.svg`}
            alt="calendar"
          />
        </label>
      </div>
      <span data-cy={`error-message-${id}`} className={styles.errorLabel}>
        {error}
      </span>
    </div>
  );
};

export default DatepickerComponent;
