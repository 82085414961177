import { useMemo } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';

import { CardHeader, Card } from '@material-ui/core';
import styles from './TotalReports.module.css';
import MessageSmoothing from '../../../shared/components/MessageSmoothing';

const COLORS = ['#3f51b5', '#fb8c00', '#e53935'];

const COLORSTOOLTIP = [
  { color: '#3f51b5', type: 'Rotinas' },
  { color: '#fb8c00', type: 'Prioridades' },
  { color: '#e53935', type: 'Urgentes' },
];

const CustomTooltip = (props: any): any => {
  if (!props.active) {
    return <div></div>;
  }

  if (!props.payload[0]) {
    return <div></div>;
  }

  const color = COLORSTOOLTIP.find(
    (color) => color.type === props.payload[0].name
  )?.color;

  return (
    <div className={styles.tooltip}>
      <div style={{ color }}>{props.payload[0].name}</div>
      <div>{props.payload[0].value.toFixed(2)}</div>
    </div>
  );
};

const TotalReports = ({ data }: any) => {
  const formatData = useMemo(
    () => [
      {
        name: 'Rotinas',
        value: (data.rotinas * 100) / data.total,
      },
      {
        name: 'Prioridades',
        value: (data.prioridades * 100) / data.total,
      },
      {
        name: 'Urgentes',
        value: (data.urgentes * 100) / data.total,
      },
    ],
    [data]
  );
  console.log(data);
  return (
    <Card style={{ marginTop: 20 }}>
      <header style={{ display: 'flex', alignItems: 'center' }}>
        <CardHeader title="Total de laudos" />
      </header>
      {data.total === 0 && (
        <MessageSmoothing
          title="Sem dados no intervalo definido"
          message="Aumente o intervalo no filtro para que tenha uma visualização"
          insideBox
        />
      )}
      <div
        style={{
          height: '300px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '25vw',
        }}
      >
        {data.total !== 0 && (
          <ResponsiveContainer>
            <PieChart
              style={{ display: 'flex', justifyContent: 'center' }}
              width={500}
              height={350}
            >
              <Pie
                data={formatData}
                innerRadius={60}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={5}
                dataKey="value"
              >
                {formatData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Legend />
              <Tooltip content={<CustomTooltip />} />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </Card>
  );
};

export default TotalReports;
