import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  SvgIcon,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import styles from './ExamsToolbar.module.css';
import { useForm } from '../../../shared/useForm';
import AutoComplete from '../../../shared/components/AutoComplete';
import formatSearchParamsToObject from '../../../shared/helpers/formatSearchParamsToObject';
import { useLocation } from 'react-router-dom';
import Datepicker from '../../../shared/components/Datepicker';
import { toast } from 'react-toastify';
import { AsyncData, get } from '../../../shared/apiClient';

interface FormValues {
  examcode: string;
  patient: string;
  procediment: string;
  doctor: string;
  company: string;
  level: string;
  limit: '100' | '50';
  status: 'registrado' | 'laudado' | 'revision' | 'espera' | '' | undefined;
  startDate?: Date | undefined;
  endDate?: Date | undefined;
}

type Fields = {
  id: number;
  name: string;
};

type SearchsFields = {
  companies: Fields[] | [];
  procedures: Fields[] | [];
  radiologists: Fields[] | [];
};

const ExamsToolbar = ({ handleSearch }: any) => {
  const { search } = useLocation();
  const [searchs, setSearchs] = useState<AsyncData<SearchsFields>>({
    status: 'not-loaded',
  });

  useEffect(() => {
    const handleLoadData = async () => {
      setSearchs({ status: 'loading' });
      try {
        const responseSearchs = await get<SearchsFields>({
          path: `/searchs`,
          credentials: true,
          query: {
            companies: true,
            procedures: true,
            radiologists: true,
          },
        });

        setSearchs({
          status: 'loaded',
          data: responseSearchs,
        });
      } catch (err) {
        setSearchs({ status: 'error' });
        toast.error('Falha ao carregar dados');
      }
    };
    handleLoadData();
  }, []);

  const initialValue = useMemo(() => {
    return search
      ? formatSearchParamsToObject<any>(search)
      : {
          examcode: '',
          patient: '',
          procediment: '',
          doctor: '',
          company: '',
          level: '',
          status: '',
          startDate: undefined,
          endDate: undefined,
        };
  }, [search]);

  const { handleChange, handleSubmit, errorIfDirty, values } =
    useForm<FormValues>({
      initialValues: {
        examcode: initialValue?.examcode || '',
        patient: initialValue?.patient || '',
        procediment: initialValue?.procediment || '',
        doctor: initialValue?.doctor || '',
        company: initialValue?.company || '',
        level: initialValue?.level || '',
        status: initialValue?.status || '',
        limit: '50',
        startDate: initialValue.startDate
          ? new Date(initialValue?.startDate)
          : undefined,
        endDate: initialValue?.endDate
          ? new Date(initialValue?.endDate)
          : undefined,
      },
      validate: (valuesToValidate, registerError) => {
        if (!valuesToValidate.startDate && valuesToValidate.endDate) {
          return registerError('startDate', 'Preencher data inicial');
        }

        if (valuesToValidate.startDate && !valuesToValidate.endDate) {
          return registerError('endDate', 'Preencher data final');
        }

        if (
          valuesToValidate.startDate &&
          valuesToValidate.endDate &&
          valuesToValidate.startDate.getTime() >
            valuesToValidate.endDate.getTime()
        ) {
          return registerError('startDate', 'Intervalo inválido');
        }
      },
      onSubmit: async (validValues) => {
        try {
          await handleSearch(validValues);
          return 'screen-still-alive';
        } catch (err: any) {
          return 'screen-still-alive';
        }
      },
    });

  return (
    <Box style={{ width: '100%', margin: '20px 0' }}>
      <form onSubmit={handleSubmit} className={styles.form}>
        <div className={styles.card}>
          <TextField
            name="examcode"
            id="examcode"
            style={{ marginRight: 10 }}
            placeholder="Examcode"
            label="Examcode"
            variant="outlined"
            onChange={(e) => {
              handleChange({ examcode: e.target.value }, 'store-only');
            }}
            value={values.examcode}
          />

          <TextField
            name="patient"
            id="patient"
            fullWidth
            style={{ marginRight: 10 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
            }}
            placeholder="Paciente"
            label="Paciente"
            variant="outlined"
            onChange={(e) => {
              handleChange({ patient: e.target.value }, 'store-only');
            }}
            value={values.patient}
          />

          {searchs.status === 'loaded' && (
            <>
              <AutoComplete
                name="procediment"
                id="procediment"
                placeholder="Procedimento"
                options={searchs.data.procedures}
                disabled={searchs.data.procedures.length === 0}
                value={values.procediment}
                handleChange={(value: any) => {
                  handleChange({ procediment: value }, 'store-only');
                }}
              />

              <AutoComplete
                name="doctor"
                id="doctor"
                placeholder="Radiologista"
                options={searchs.data.radiologists}
                disabled={searchs.data.radiologists.length === 0}
                value={values.doctor}
                handleChange={(value: any) => {
                  handleChange({ doctor: value }, 'store-only');
                }}
              />
              <AutoComplete
                name="company"
                id="company"
                placeholder="Clinica"
                options={searchs.data.companies}
                disabled={searchs.data.companies.length === 0}
                value={values.company}
                handleChange={(value: any) => {
                  handleChange({ company: value }, 'store-only');
                }}
              />
            </>
          )}
        </div>
        <div className={styles.card}>
          <FormControl
            variant="outlined"
            style={{ width: 200, marginRight: 10 }}
          >
            <InputLabel>Prioridade</InputLabel>
            <Select
              name="level"
              id="level"
              onChange={(e) => {
                handleChange({ level: e.target.value }, 'store-only');
              }}
              value={values.level}
              label="Prioridade"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="urgente">Urgência</MenuItem>
              <MenuItem value="rotina">Rotina</MenuItem>
            </Select>
          </FormControl>
          <FormControl
            variant="outlined"
            style={{ width: 200, marginRight: 10 }}
          >
            <InputLabel>status</InputLabel>
            <Select
              name="status"
              id="status"
              onChange={(e) => {
                handleChange({ status: e.target.value }, 'store-only');
              }}
              value={values.status}
              label="status"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value="registrado">Registrado</MenuItem>
              <MenuItem value={5}>Assinado</MenuItem>
              <MenuItem value={3}>Revisão</MenuItem>
              <MenuItem value="espera">Em espera</MenuItem>
            </Select>
          </FormControl>
          <Datepicker
            placeholderText="DD/MM/AAAA"
            id="startDate"
            label="Data inicial"
            value={values.startDate}
            className={styles.datepicker}
            onChange={(date) => handleChange({ startDate: date }, 'store-only')}
            error={errorIfDirty('startDate')}
          />
          <Datepicker
            placeholderText="DD/MM/AAAA"
            id="endDate"
            label="Data final"
            value={values.endDate}
            className={styles.datepicker}
            onChange={(date) =>
              handleChange({ endDate: date || undefined }, 'store-only')
            }
            error={errorIfDirty('endDate')}
          />
          <FormControl
            variant="outlined"
            style={{ width: 200, marginRight: 10 }}
          >
            <InputLabel>QTD linhas na tabela</InputLabel>
            <Select
              name="limit"
              id="limit"
              onChange={(e) => {
                handleChange({ limit: e.target.value }, 'store-only');
              }}
              value={values.limit}
              label="QTD linhas na tabela"
            >
              <MenuItem value="50">50</MenuItem>
              <MenuItem value="100">100</MenuItem>
            </Select>
          </FormControl>
          <Button color="primary" type="submit" variant="contained">
            Buscar
          </Button>
        </div>
      </form>
    </Box>
  );
};

export default ExamsToolbar;
