import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import { get, useHandleBackgroundError } from '../../shared/apiClient';

import { Loading } from './Loading';
import SessionContext from './SessionContext';
import { User } from './model';
import { resolveAuthToken } from '../../shared/authToken';

type SessionStatus =
  | { status: 'loading' }
  | { status: 'loaded'; user: User | null }
  | { status: 'crash' };

type ApiUser = {
  user: {
    name: string;
    email: string;
  };
};

async function fetchUser(): Promise<ApiUser> {
  return await get<ApiUser>({ path: '/users/me', credentials: true });
}

export default function SessionProvider({
  children,
}: PropsWithChildren<any>): JSX.Element {
  const history = useHistory();
  const { pathname } = useLocation();
  const handleBackgroundError = useHandleBackgroundError();

  const [state, setState] = useState<SessionStatus>({
    status: 'loading',
  });

  useEffect(() => {
    if (resolveAuthToken() === null) {
      setState({
        status: 'loaded',
        user: null,
      });
      return;
    }
    (async () => {
      try {
        const apiUser = await fetchUser();
        setState({
          status: 'loaded',
          user: apiUser.user,
        });
        if (pathname === '/') {
          history.push('/app/dashboard/overview');
        }
      } catch (err: any) {
        const navigatedAway = handleBackgroundError(err);
        if (navigatedAway) {
          setState({
            status: 'loaded',
            user: null,
          });
        } else {
          setState({ status: 'crash' });
        }
      }
    })();
  }, []);

  const onLogin = useCallback(async () => {
    try {
      const apiUser = await fetchUser();
      setState({
        status: 'loaded',
        user: apiUser.user,
      });
    } catch (err: any) {
      const navigatedAway = handleBackgroundError(err);
      if (navigatedAway) {
        setState({
          status: 'loaded',
          user: null,
        });
      } else {
        setState({ status: 'crash' });
      }
    }
  }, []);

  if (state.status === 'loading') {
    return <Loading />;
  }
  if (state.status === 'crash') {
    return <span>Não foi possível obter os dados do usuário autenticado.</span>;
  }
  if (state.status === 'loaded') {
    return (
      <SessionContext.Provider
        value={{
          user: state.user,
          onLogin,
        }}
      >
        {children}
      </SessionContext.Provider>
    );
  }

  return <span>Unknown session state: {JSON.stringify(state)}.</span>;
}
