/* eslint-disable react/destructuring-assignment */
import { useEffect, useState } from 'react';
import { Card, CardHeader } from '@material-ui/core';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { AsyncData, get } from '../../../shared/apiClient';
import { toast } from 'react-toastify';
import Loading from '../../../shared/components/Loading';
import MessageSmoothing from '../../../shared/components/MessageSmoothing';

type CountOverview = {
  ur: number;
  rotinas: number;
  urgentes: number;
};

const CustomizedAxisTick = ({ x, y, payload }: any) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-45)"
    >
      {payload.value && new Date(payload.value).toISOString().substring(0, 10)}
    </text>
  </g>
);

const ReportsDays = ({ data }: any) => {
  return (
    <Card style={{ height: 600 }}>
      <header
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CardHeader title="Evolução diária dos laudos (UR)" />
      </header>
      {data.length === 0 && (
        <MessageSmoothing
          title="Sem dados no intervalo definido"
          message="Aumente o intervalo no filtro para que tenha uma visualização"
          insideBox
        />
      )}
      {data.length !== 0 && (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            width={500}
            height={300}
            data={data}
            margin={{
              right: 50,
              left: 20,
              bottom: 150,
              top: 20,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
            <YAxis tickCount={10} />
            <Tooltip labelStyle={{ color: '#000' }} />
            <Legend
              align="center"
              alignmentBaseline="central"
              verticalAlign="top"
              height={30}
              payload={[
                { value: 'ur', type: 'diamond', color: '#5664d2' },
                { value: 'urgente', type: 'cross', color: '#E53935' },
                { value: 'rotina', type: 'circle', color: '#82ca9d' },
              ]}
            />
            <Line type="linear" dataKey="ur" stroke="#5664d2" fill="#5664d2" />
            <Line
              type="linear"
              dataKey="urgentes"
              stroke="#E53935"
              fill="#E53935"
            />
            <Line
              type="linear"
              dataKey="rotinas"
              stroke="#82ca9d"
              fill="#82ca9d"
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </Card>
  );
};

export default ReportsDays;
