import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { removeAuthToken, resolveAuthToken } from './authToken';

const HOSTS = process.env.REACT_APP_API_URL || 'please-set-up-api-url';

export type AsyncData<T> =
  | { status: 'not-loaded' }
  | { status: 'loading' }
  | { status: 'error' }
  | { status: 'loaded'; data: T };

interface PostOptions {
  path: string;
  credentials?: boolean;
  body?: any;
  query?: any;
}

export const get = async <T>({
  path,
  credentials,
  query,
}: {
  path: string;
  credentials?: boolean;
  query?: any;
}): Promise<T> => await API({ method: 'GET', path, credentials, query });

export const post = async ({ path, credentials, body, query }: PostOptions) =>
  await API({
    method: 'POST',
    path,
    credentials,
    body: JSON.stringify(body),
    query,
  });

interface ApiParams {
  method: 'GET' | 'POST' | 'DELETE' | 'PUT';
  path: string;
  credentials?: boolean;
  body?: BodyInit;
  query?: Record<string, string>;
}

const queryFormatted = (query?: Record<string, string>): any => {
  if (query) {
    const queryWithoutUndefinedValues = Object.entries(query).filter(
      (data) => data[1] !== undefined
    );
    return `?${new URLSearchParams(
      Object.fromEntries(queryWithoutUndefinedValues)
    ).toString()}`;
  }
  return '';
};

async function API({
  method,
  path,
  body,
  credentials,
  query,
}: ApiParams): Promise<any | void> {
  try {
    const headers: Record<string, string> = {};
    const authToken = resolveAuthToken();
    headers['content-type'] = 'application/json';
    if (authToken !== null && credentials) {
      headers['authorization'] = `Bearer ${authToken}`;
    }

    const resp = await fetch(`${HOSTS}${path}${queryFormatted(query)}`, {
      method,
      credentials: credentials ? 'include' : 'omit',
      headers,
      body,
    });
    if (resp.status === 401) {
      removeAuthToken();
      const { error } = await resp.json();
      throw new UnauthorizedApiClientError(error);
    }
    if (resp.status === 422) {
      const { error, description } = await resp.json();
      throw new KnownHumanReadableApiClientError(error, description);
    }
    if (resp.status >= 400 && resp.status <= 499) {
      const text = await resp.text();
      let json;
      try {
        json = JSON.parse(text);
      } catch (err) {
        console.warn(`api-client: received a 4xx without a JSON body`, err);
        throw new BadRequestApiClientError(text);
      }
      if (json.error) {
        throw new BadRequestApiClientError(json.error);
      } else {
        throw new BadRequestApiClientError(text);
      }
    }
    if (resp.status < 200 || resp.status > 299) {
      // these include 5xx and everything else not handled above
      throw new ServerUnavailabilityOrBugApiClientError();
    }
    if (resp.status === 204) {
      return;
    } else {
      return await resp.json();
    }
  } catch (err: any) {
    if (err.kind) {
      // already an ApiClientError
      throw err;
    } else {
      throw new UnknownApiClientError(err.message || err);
    }
  }
}

type ApiClientErrorKind =
  | 'unknown'
  | 'known-human-readable'
  | 'server-unavailability-or-bug'
  | 'bad-request'
  | 'unauthorized';

export class ApiClientError extends Error {
  kind: ApiClientErrorKind;

  constructor(kind: ApiClientErrorKind, details?: string) {
    super(`kind=${kind}; ${details}`);
    this.kind = kind;
  }
}

class UnauthorizedApiClientError extends ApiClientError {
  constructor(description: string) {
    super('unauthorized', `description=${description}`);
  }
}

class UnknownApiClientError extends ApiClientError {
  constructor(description: string) {
    super('unknown', `description=${description}`);
  }
}

class BadRequestApiClientError extends ApiClientError {
  description: string;

  constructor(description: string) {
    super('bad-request', `description=${description}`);
    this.description = description;
  }
}

class KnownHumanReadableApiClientError extends ApiClientError {
  error: string;
  description: string;

  constructor(error: string, description: string) {
    super('known-human-readable', `error=${error}; description=${description}`);
    this.error = error;
    this.description = description;
  }
}

class ServerUnavailabilityOrBugApiClientError extends ApiClientError {
  constructor() {
    super('server-unavailability-or-bug', 'Internal Server Error');
  }
}

export type IdentifiedRequestError =
  | { kind: 'known-human-readable'; description: string }
  | { kind: 'unknown'; description: string }
  | { kind: 'server-unavailability-or-bug' }
  | { kind: 'unauthorized' }
  | { kind: 'bad-request' };

type NavigatedAway = boolean;

export function useHandleBackgroundError() {
  const history = useHistory();
  return useCallback(
    (err: IdentifiedRequestError): NavigatedAway => {
      switch (err.kind) {
        case 'unauthorized':
          toast.warn('Sessão expirada, entre novamente');
          history.push('/');
          return true;
        default:
          console.error('useHandleBackgroundError: unexpected error', err);
          return false;
      }
    },
    [history]
  );
}

export function useHandleForegroundError() {
  const history = useHistory();
  return useCallback(
    (err: IdentifiedRequestError): NavigatedAway => {
      switch (err.kind) {
        case 'unauthorized':
          toast.warn('Sessão expirada, entre novamente');
          history.push('/');
          return true;
        case 'known-human-readable':
          toast.error(err.description);
          return false;
        case 'bad-request':
          console.warn('useHandleForegroundError: bad-request', err);
          toast.error('Dados inválidos');
          return false;
        case 'server-unavailability-or-bug':
          console.error(
            'useHandleForegroundError: server-unavailability-or-bug',
            err
          );
          toast.error('Falha no servidor, tente mais tarde');
          return false;
        case 'unknown':
          console.error('useHandleForegroundError: unexpected error', err);
          toast.error('Falha desconhecida');
          return false;
        default:
          console.error('useHandleForegroundError: unexpected error', err);
          toast.error('Falha em formato desconhecido');
          return false;
      }
    },
    [history]
  );
}
