import { Box, Container } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Button from '../../shared/components/Button';
import Input from '../../shared/components/Input';
import { useHandleForegroundError, post } from '../../shared/apiClient';
import { useForm } from '../../shared/useForm';
import { storeAuthToken } from '../../shared/authToken';
import { useSession } from '../../core/Session';
import styles from './Login.module.css';
import Title from '../../shared/components/Title';

interface FormValues {
  username: string;
  password: string;
}

const Login = () => {
  const { onLogin } = useSession();
  const handleForegroundError = useHandleForegroundError();
  const history = useHistory();

  const {
    handleChange,
    handleSubmit,
    errorIfDirty,
    markAsDirty,
    values,
    submitting,
  } = useForm<FormValues>({
    initialValues: {
      username: '',
      password: '',
    },
    validate: (valuesToValidate, registerError) => {
      if (valuesToValidate.username.length === 0) {
        registerError('username', 'Campo obrigatório.');
      }
      if (valuesToValidate.password.length === 0) {
        registerError('password', 'Campo obrigatório.');
      }
    },
    onSubmit: async (validValues) => {
      try {
        const resp = await post({
          path: '/authentication/login',
          body: {
            username: validValues.username,
            password: validValues.password,
          },
        });
        storeAuthToken(resp.token);
        await onLogin();
        history.push('/app/dashboard/overview');
        return 'screen-destroyed';
      } catch (err: any) {
        handleForegroundError(err);
        return 'screen-still-alive';
      }
    },
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
      }}
    >
      <Container maxWidth="sm">
        <form className={styles.form} onSubmit={handleSubmit} data-cy="form">
          <Title>Entrar</Title>
          <Input
            type="text"
            value={values.username}
            onChange={(e) =>
              handleChange({ username: e.target.value }, 'store-only')
            }
            onBlur={() => markAsDirty('username')}
            errorMessage={errorIfDirty('username')}
            placeholder="Username"
            id="username"
            data-cy="username"
          />
          <Input
            type="password"
            value={values.password}
            onChange={(e) =>
              handleChange({ password: e.target.value }, 'store-only')
            }
            onBlur={() => markAsDirty('password')}
            errorMessage={errorIfDirty('password')}
            placeholder="Senha"
            id="password"
            data-cy="password"
          />
          <div className={styles.action}>
            <Button
              loading={submitting}
              className={styles.button}
              data-cy="submit"
              type="submit"
            >
              Continuar
            </Button>
          </div>
        </form>
      </Container>
    </Box>
  );
};

export default Login;
