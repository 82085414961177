import { useState, useEffect, useMemo } from 'react';
import { Card, Grid, Box, Container } from '@material-ui/core';
import { subDays } from 'date-fns';
import { useLocation, useHistory } from 'react-router-dom';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { toast } from 'react-toastify';
import {
  AsyncData,
  get,
  useHandleBackgroundError,
} from '../../shared/apiClient';
import formatSearchParamsToObject from '../../shared/helpers/formatSearchParamsToObject';
import AutoComplete from '../../shared/components/AutoComplete';
import Button from '../../shared/components/Button';
import styles from './OverviewCompanies.module.css';
import { useForm } from '../../shared/useForm';
import Loading from '../../shared/components/Loading';
import MessageSmoothing from '../../shared/components/MessageSmoothing';

type RowsOptions = {
  date: string;
  ur: number;
  cd_empresa: string;
  laudos: string;
};

type OverviewCompaniesParams = {
  review: number;
  total: number;
  rows: RowsOptions[];
};

type CompaniesOptions = {
  name: string;
  id: number;
};

type FormValues = {
  company: number | undefined | '';
};

const CustomizedAxisTick = ({ x, y, payload }: any) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={16}
      textAnchor="end"
      fill="#666"
      transform="rotate(-45)"
    >
      {payload.value}
    </text>
  </g>
);

const Dashboard = () => {
  const [results, setResults] = useState<AsyncData<OverviewCompaniesParams>>({
    status: 'not-loaded',
  });
  const [companies, setCompanies] = useState<CompaniesOptions[] | []>([]);
  const handleForegroundError = useHandleBackgroundError();
  const { search } = useLocation();
  const history = useHistory();

  const media = useMemo(() => {
    if (results.status !== 'loaded') {
      return 0;
    }
    if (results.data.rows.length !== 0) {
      const temp = results.data.rows.map((dt: any) => dt?.ur);
      return Number(
        (
          temp?.reduce(
            (accumulator: any, currentValue: any) =>
              Number(accumulator) + Number(currentValue)
          ) / results.data.rows.length
        ).toFixed(2)
      );
    }
    return 0;
  }, [results]);

  const YAxisInterval = useMemo(() => {
    if (results.status !== 'loaded') {
      return 0;
    }
    return Math.max(...results.data.rows.map((o) => Number(o.ur) + 10), 0);
  }, [results]);

  const variation = useMemo(() => {
    if (results.status !== 'loaded' || results.data.rows.length === 0) {
      return '';
    }
    const rows = results.data.rows;

    if (
      Number(rows[rows.length - 1].ur) &&
      Number(rows[rows.length - 2].ur) !== 0
    ) {
      return '';
    }

    const value =
      ((Number(rows[rows.length - 1]?.ur) - Number(rows[rows.length - 2]?.ur)) /
        Number(rows[rows.length - 2]?.ur)) *
      100;

    return `${value.toFixed(2)}%`;
  }, [results]);

  useEffect(() => {
    const loadCompanies = async () => {
      try {
        const { companies: resp } = await get<{
          companies: CompaniesOptions[];
        }>({
          path: `/searchs`,
          credentials: true,
          query: {
            companies: true,
          },
        });
        setCompanies(resp);
        if (search) {
          setResults({ status: 'loading' });
          const query = formatSearchParamsToObject<any>(search);
          if (!query.id) {
            return setResults({
              status: 'not-loaded',
            });
          }
          const resp = await get<OverviewCompaniesParams>({
            path: `/overview/${query.id}/ur-individual-company`,
            query: {
              initDate: subDays(new Date(), 90),
              finalDate: new Date(),
            },
            credentials: true,
          });

          setResults({
            status: 'loaded',
            data: resp,
          });
        }
      } catch (error) {
        setResults({
          status: 'error',
        });
        toast.error('Falha ao carregar dados');
      }
    };
    loadCompanies();
  }, []);

  const { handleChange, handleSubmit, errorIfDirty, markAsDirty } =
    useForm<FormValues>({
      initialValues: {
        company: '',
      },
      validate: (valuesToValidate, registerError) => {
        if (!valuesToValidate.company) {
          registerError('company', 'Campo obrigatório.');
        }
      },
      onSubmit: async (validValues: any) => {
        setResults({ status: 'loading' });
        const params = new URLSearchParams({
          id: validValues.company,
        }).toString();

        try {
          const resp = await get<OverviewCompaniesParams>({
            path: `/overview/${validValues.company}/ur-individual-company`,
            credentials: true,
          });
          history.push({ search: params });
          setResults({ status: 'loaded', data: resp });
          return 'screen-still-alive';
        } catch (err: any) {
          setResults({ status: 'error' });
          handleForegroundError(err);
          return 'screen-still-alive';
        }
      },
    });

  return (
    <Box
      sx={{
        minHeight: '100%',
        py: 3,
      }}
    >
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Box className={styles.toolbar}>
            <form className={styles.form} onSubmit={handleSubmit}>
              <AutoComplete
                handleChange={(data: any) =>
                  handleChange({ company: data }, 'store-only')
                }
                options={companies}
                id="company"
                errorMessage={errorIfDirty('company')}
                onBlur={() => markAsDirty('company')}
                placeholder="selecione uma empresa"
              />
              <Button disabled={results.status === 'loading'}>
                {results.status === 'loading' ? 'Carregando' : 'Buscar'}
              </Button>
            </form>
          </Box>
          <Grid item lg={12} md={12} xl={12} xs={12}>
            {results.status === 'loaded' && (
              <Grid container spacing={3}>
                <Grid item md={3} lg={3} xl={3} xs={3}>
                  <Card
                    style={{
                      height: 290,
                      padding: 10,
                      marginTop: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 54,
                    }}
                  >
                    <span style={{ textAlign: 'center' }}>
                      Media
                      <br /> <h4 style={{ textAlign: 'center' }}>{media}</h4>
                    </span>
                  </Card>
                </Grid>
                <Grid item md={3} lg={3} xl={3} xs={3}>
                  <Card
                    style={{
                      height: 290,
                      padding: 10,
                      marginTop: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 54,
                    }}
                  >
                    <span style={{ textAlign: 'center' }}>
                      Variação <br />
                      <h4 style={{ textAlign: 'center' }}>{variation || 0}</h4>
                    </span>
                  </Card>
                </Grid>
                <Grid item md={3} lg={3} xl={3} xs={3}>
                  <Card
                    style={{
                      height: 290,
                      padding: 10,
                      marginTop: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 34,
                    }}
                  >
                    <span style={{ textAlign: 'center' }}>
                      Revisões no total
                      <br />{' '}
                      <h4 style={{ textAlign: 'center' }}>
                        {results.data.total}
                      </h4>
                    </span>
                  </Card>
                </Grid>
                <Grid item md={3} lg={3} xl={3} xs={3}>
                  <Card
                    style={{
                      height: 290,
                      padding: 10,
                      marginTop: 10,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      fontSize: 34,
                    }}
                  >
                    <span style={{ textAlign: 'center' }}>
                      Revisões em aberto
                      <br />{' '}
                      <h4 style={{ textAlign: 'center' }}>
                        {results.data.review}
                      </h4>
                    </span>
                  </Card>
                </Grid>

                <Grid item md={12} lg={12} xl={12} xs={12}>
                  <Card style={{ height: 600, marginTop: 10 }}>
                    <ResponsiveContainer width="100%" height="100%">
                      <LineChart
                        width={500}
                        height={500}
                        data={results.data.rows}
                        margin={{
                          right: 50,
                          left: 20,
                          bottom: 80,
                          top: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="1 1" />
                        <XAxis dataKey="date" tick={<CustomizedAxisTick />} />
                        <YAxis
                          type="number"
                          domain={[0, YAxisInterval]}
                          allowDataOverflow
                          allowDecimals
                          tickCount={10}
                        />
                        <Tooltip labelStyle={{ color: '#000' }} />
                        <Legend
                          align="center"
                          alignmentBaseline="central"
                          verticalAlign="top"
                          height={30}
                          payload={[
                            { value: 'ur', type: 'diamond', color: '#5664d2' },
                            {
                              value: 'laudos',
                              type: 'circle',
                              color: '#82ca9d',
                            },
                          ]}
                        />
                        <Line
                          type="linear"
                          dataKey="ur"
                          stroke="#5664d2"
                          fill="#5664d2"
                        />
                        <Line
                          type="linear"
                          dataKey="laudos"
                          stroke="#82ca9d"
                          fill="#82ca9d"
                        />
                      </LineChart>
                    </ResponsiveContainer>
                  </Card>
                </Grid>
              </Grid>
            )}
            {results.status === 'error' && (
              <MessageSmoothing
                title="Sem dados disponível"
                message="Altere os filtros e faça sua consulta"
              />
            )}
            {results.status === 'not-loaded' && (
              <MessageSmoothing
                title="Faça sua consulta"
                message="Selecione uma empresa para que os dados referente a ela apareçam"
              />
            )}
            {results.status === 'loading' && (
              <div style={{ backgroundColor: '#fff', padding: 50 }}>
                <Loading />
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
