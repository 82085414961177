import styles from './Pagination.module.css';

interface PaginationParams {
  handlePage: (pg: number) => void;
  selectedPage: number;
  pages: number[];
  className: string;
}

const Pagination = ({
  selectedPage,
  handlePage,
  pages,
  className,
}: PaginationParams): JSX.Element => {
  return (
    <>
      <div className={className}>
        <span
          className={`${styles.page} ${
            pages.length - 1 === selectedPage && styles.disabled
          } ${selectedPage === 0 && styles.disabled}`}
          onClick={() =>
            selectedPage !== 0 &&
            pages.length - 1 !== selectedPage &&
            handlePage(selectedPage - 1)
          }
        >
          Anterior
        </span>
        <span className={styles.staticPage} style={{ cursor: 'default' }}>
          {selectedPage}
        </span>
        <span
          className={`${styles.page} ${
            pages.length - 1 === selectedPage && styles.disabled
          }`}
          onClick={() =>
            pages.length - 1 !== selectedPage && handlePage(selectedPage + 1)
          }
        >
          Próximo
        </span>
      </div>
    </>
  );
};

export default Pagination;
