import { useEffect, useState, useMemo } from 'react';
import { Container } from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@material-ui/core';
import { PageviewRounded } from '@material-ui/icons';
import { NavLink } from 'react-router-dom';
import { useForm } from '../../shared/useForm';
import Datepicker from '../../shared/components/Datepicker';
import Button from '../../shared/components/Button';

import { AsyncData, get } from '../../shared/apiClient';
import Loading from '../../shared/components/Loading';
import { format } from 'date-fns';
import styles from './LatestWeekTable.module.css';

type TotalParams = {
  ds_empresa: string;
  cd_empresa: number;
  week1: string;
  week2: string;
  week3: string;
  week4: string;
  lastReport: string;
};

type LatestWeekTableResults = {
  week1: string;
  week2: string;
  week3: string;
  week4: string;
  total: TotalParams[];
};

type FormValues = {
  date: Date;
  order: 'ASC' | 'DESC';
};

const LatestWeekTable = () => {
  const [results, setResults] = useState<AsyncData<LatestWeekTableResults>>({
    status: 'not-loaded',
  });

  useEffect(() => {
    const handleLoadData = async () => {
      try {
        setResults({ status: 'loading' });
        const response = await get<LatestWeekTableResults>({
          path: `/overview/ur-last-week`,
          credentials: true,
        });
        setResults({ status: 'loaded', data: response });
      } catch (error) {
        setResults({ status: 'error' });
        toast.error('Falha ao carregar dados');
      }
    };
    handleLoadData();
  }, []);

  const variation = useMemo((): any => {
    if (results.status !== 'loaded') {
      return;
    }
    return results.data.total?.map(
      (row: any) =>
        Number(row.week3) !== 0 &&
        Number(row.week4 !== 0) &&
        `${(
          ((Number(row.week4) - Number(row.week3)) / Number(row.week3)) *
          100
        ).toFixed(2)}%`
    );
  }, [results]);

  const media = useMemo(
    () =>
      results.status === 'loaded'
        ? results.data.total?.map((row: any) =>
            (
              (Number(row.week1) +
                Number(row.week2) +
                Number(row.week3) +
                Number(row.week4)) /
              4
            ).toFixed(2)
          )
        : [],
    [results]
  );

  const { handleChange, handleSubmit, errorIfDirty, values } =
    useForm<FormValues>({
      initialValues: {
        date: new Date(),
        order: 'ASC',
      },
      validate: () => {},
      onSubmit: async (validValues) => {
        try {
          setResults({ status: 'loading' });
          const response = await get<LatestWeekTableResults>({
            path: `/overview/ur-last-week`,
            query: {
              date: validValues.date
                ? format(validValues.date, 'yyyy-MM-dd')
                : undefined,
              order: validValues.order,
            },
            credentials: true,
          });

          setResults({ status: 'loaded', data: response });
        } catch (error: any) {
          toast.error('Falha ao carregar dados');
          setResults({ status: 'error' });
        }
        return 'screen-destroyed';
      },
    });

  return (
    <Container maxWidth={false}>
      <Box>
        <Box sx={{ mt: 3 }}>
          <form onSubmit={handleSubmit}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                background: '#fff',
                padding: '5px 20px',
              }}
            >
              <Datepicker
                id="date"
                placeholderText="dd/MM/AAAA"
                className={styles.date}
                onChange={(date) => handleChange({ date: date }, 'store-only')}
                value={values.date}
                error={errorIfDirty('date')}
              />
              <FormControl
                variant="outlined"
                style={{ width: 140, marginRight: 20 }}
              >
                <InputLabel id="order">ORDERNAR</InputLabel>
                <Select
                  labelId="order"
                  label="ORDERNAR"
                  id="order"
                  name="order"
                  value={values.order}
                  onChange={(e) =>
                    handleChange({ order: e.target.value }, 'store-only')
                  }
                >
                  <MenuItem value="ASC">ASC</MenuItem>
                  <MenuItem value="DESC">DESC</MenuItem>
                </Select>
              </FormControl>

              <Button type="submit">Buscar</Button>
            </div>
          </form>
        </Box>
      </Box>
      <Box sx={{ pt: 3 }}>
        <Card>
          {results.status === 'loading' && <Loading />}

          {results.status === 'loaded' && (
            <Box sx={{ minWidth: 1050 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Empresa</TableCell>
                    <TableCell>Último laudo</TableCell>
                    <TableCell>
                      {results.data.week1 && results.data.week1}
                    </TableCell>
                    <TableCell>
                      {results.data.week2 && results.data.week2}
                    </TableCell>
                    <TableCell>
                      {results.data.week3 && results.data.week3}
                    </TableCell>
                    <TableCell>
                      {results.data.week4 && results.data.week4}
                    </TableCell>
                    <TableCell>Diferença Última semana</TableCell>
                    <TableCell>Media</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {results.data.total.map((row: any, index: any) => (
                    <TableRow hover key={row.id}>
                      <TableCell>
                        <Box
                          sx={{
                            alignItems: 'center',
                            display: 'flex',
                          }}
                        >
                          <Typography color="textPrimary" variant="body1">
                            {row.cd_empresa}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>{row.ds_empresa}</TableCell>
                      <TableCell>{row.lastReport}</TableCell>
                      <TableCell>{row.week1.replace('.', ',')}</TableCell>
                      <TableCell>{row.week2.replace('.', ',')}</TableCell>
                      <TableCell>{row.week3.replace('.', ',')}</TableCell>
                      <TableCell>{row.week4.replace('.', ',')}</TableCell>
                      <TableCell>
                        {variation[index]
                          ? variation[index].replace('.', ',')
                          : '0%'}
                      </TableCell>
                      <TableCell>{media[index].replace('.', ',')}</TableCell>
                      <TableCell>
                        <NavLink
                          to={`/app/dashboard/overview/companies?id=${row.cd_empresa}`}
                        >
                          <IconButton>
                            <PageviewRounded color="primary" />
                          </IconButton>
                        </NavLink>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          )}
        </Card>
      </Box>
    </Container>
  );
};

export default LatestWeekTable;
